

export default {
  name: 'FooterPanel',
  setup() {
    const version = localStorage.getItem('version')

    return {
      version
    }
  }
}

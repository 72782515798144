
import {
  onMounted,
  ref
} from 'vue'
import $ from 'jquery'
import {
  useStore
} from 'vuex'
import {
  globalSettingUseCase
} from '@/domain/usecase'
import {
  isEmpty
} from 'lodash'
import {
  environment
} from '@/utils'

export default {
  setup() {
    const store = useStore()
    const countDown = ref(5)
    const dataImage = ref(null) as any
    const API_BASE = environment.getApiBase()
    const countDownTimer = () => {
      if (countDown.value > 0) {
        setTimeout(() => {
          countDown.value -= 1
          countDownTimer()
        }, 1000)
      }
    }
    const hide = () => {
      store.dispatch('hideAdvertise')
    }
    const showImage = (img: any) => {
      // $('.container-img-advertise').css('background-image', `url(${img})`)
      $('.img-bg').attr('src', `${API_BASE}${img}`)
      $('.img-bg').on('error', () => {
        hide()
      })
      countDownTimer()
    }
    const getGlobalSetting = () => {
      globalSettingUseCase.getAll({
        custom: 'Kode eq \'Banner1\''
      }).then((response) => {
        if (!response.error) {
          if (response.result.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            dataImage.value = response.result[0]
            // dataImage.value.Value
            // 'https://stgapi.hjex.co.id/media/voucher/2022/jpg/vltw5khbvdn.jpg'
            // require('@/assets/img/try-advertise.png')
            showImage(isEmpty(dataImage.value.Value) ? hide() : dataImage.value.Value)
          }
        }
      })
    }

    onMounted(() => {
      getGlobalSetting()
    })
    return {
      countDown,
      hide
    }
  }
}
